<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="order === undefined"
    >
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
      <div class="alert-body">
        No order found with this order id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          order List
        </b-link>
        for other order.
      </div>
    </b-alert>

    <b-row
      v-if="order"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div style="color: black">
                <div class="logo-wrapper mb-4">
                  <img
                    src="@/assets/images/logo/logo bioherbs-01.png"
                    width="250px"
                    height="auto"
                  >
                  <h3 class="text-primary invoice-logo" />
                </div>
              </div>
              <!-- Header: Right Content -->
              <!-- Header: Right Content -->
            </div>
          </b-card-body>
          <!-- Spacer -->
          <b-card-body>
            <div
              style="color: black"
              class="d-flex flex-row justify-content-between align-items-center"
            >

              <div>
                <h3
                  style="color: black; font-size: 35px"
                >
                  Facture
                  <span
                    v-if="generate < 10"
                    style="color: black ; font-size: 20px;"
                  >
                    N° FA{{ formatDate(order.date ) }}000{{ generate }}
                  </span>
                  <span
                    v-if="generate < 100 && generate > 10"
                    style="color: black ; font-size: 20px;"
                  >
                    N° FA{{ formatDate(order.date ) }}00{{ generate }}
                  </span>
                  <span
                    v-if="generate < 1000 && generate > 100"
                    style="color: black ; font-size: 20px;"
                  >
                    N° FA{{ formatDate(order.date ) }}0{{ generate }}
                  </span>
                  <span
                    v-if="generate > 1000 "
                    style="color: black ;font-size: 20px ;"
                  >
                    N° FA{{ formatDate(order.date ) }}{{ generate }}
                  </span>
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  Date:
                  <span
                    style="color: black"
                  >
                    {{ format(order.date) }}</span>
                </h3>
              </div>

            </div>
          </b-card-body>

          <b-card-body class="invoice-padding pb-0">

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                v-if="order.customer"
                class="mb-2 mt-2"
                style="
                  border-bottom: 2px solid black;
                  border-right: 2px solid black;
                  border-left: 2px solid black;
                  border-radius: 15px;
                  font-size: 22px;
                "
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span>STE :</span> Bioherbs
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Adresse : </span>Dar Chabane , Nabeul
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Tel :</span>
                  +216 24 289 289
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold"> MF :</span>
                  000MB1704494/H                </b-card-text>
                <!-- <b-card-text class="ml-2 mr-2mb-0">
                  www.bioherbs.tn
                </b-card-text> -->
              </div>
              <div
                v-if="order.customer"
                class="mb-2 mt-2"
                style="
                  border-bottom: 2px solid black;
                  border-right: 2px solid black;
                  border-left: 2px solid black;
                  border-radius: 15px;
                  font-size: 22px;
                "
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span>Client :</span> {{ order.customer.company_name }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Adresse : </span>{{ order.customer.address }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span class="font-weight-bold">Tel :</span>
                  {{ order.customer.phone }} {{ order.customer.mobile }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    v-if="order.customer.type === 'client physique'"
                    class="font-weight-bold"
                  > CIN :</span>
                  <span
                    v-else
                    class="font-weight-bold"
                  > MF :</span>

                  {{ order.customer.tax_identification_number }}
                </b-card-text>
                <!-- <b-card-text class="ml-2 mr-2mb-0">
                  www.bioherbs.tn
                </b-card-text> -->
              </div>
            </div>

          </b-card-body>
          <hr class="invoice-spacing">

          <!-- Invoice Description: Table -->
          <b-card-body class="invoice-padding pt-5">
            <b-table-lite
              style="font-size: 22px; border: 2px solid black"
              responsive
              :items="p"
              :fields="['désignation', 'quantité', 'prix unitaire', 'remise','montant HT']"
            >
              <template #cell(taskDescription)="data">
                <b-card-text
                  class="font-weight-bold mb-25"
                  style="font-size: 25px; border: 2px solid black"
                >
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <!-- Invoice Description: Total -->
          <hr class="invoice-spacing">

          <b-card-body
            class="invoice-padding pb-10 mt-5"
            style="display: flex; justify-content: flex-end"
          >
            <!-- Col: Total -->
            <b-col
              cols="12"
              md="6"
              class="mt-md-6 d-flex justify-content-left"
            >
              <div
                class="mb-4"
                style="font-size: 22px"
              >
                <table v-if="order">
                  <tr>
                    <th>Code</th>
                    <th>Base</th>
                    <th>Taux</th>
                    <th>Montant</th>
                  </tr>
                  <tr>
                    <td>TVA COL</td>

                    <td v-if="order">
                      {{ order.total_ht }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td>19,0</td>
                    <td v-if="order">
                      {{ order.tax }}
                    </td>
                    <td v-else>
                      0
                    </td>
                  </tr>
                </table>
              </div></b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-md-6 d-flex justify-content-left"
              style="display: flex; justify-content: flex-end"
            >
              <div
                class="mb-4"
                style="font-size: 22px"
              >
                <table v-if="order">
                  <tr>
                    <th>Total HT</th>
                    <th>Total Taxes</th>
                    <th>Timbre</th>
                    <th>Total TTC</th>
                  </tr>
                  <tr>
                    <td v-if="order">
                      {{ order.total_ht }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td v-if="order">
                      {{ order.tax }}
                    </td>
                    <td v-else>
                      0
                    </td>
                    <td>1</td>
                    <td v-if="order">
                      {{ (Number(order.total_ttc) + 1).toFixed(3) }}
                    </td>
                    <td v-else>
                      0
                    </td>
                  </tr>
                </table>
                <div class="font-weight-bold  mt-1 color:black">
                  {{ lettre }}
                </div>
              </div></b-col>
          </b-card-body>
          <hr class="invoice-spacing">

          <footer
            class="d-flex justify-content-around align-items-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <!-- Header: Left Content -->
            <div class="contact">
              <h3 style="color: white">
                contact@bioherbs.tn
              </h3>
            </div>
            <div
              style="color: black"
              class="d-flex flex-column justify-content-center align-items-center mt-0"
            >
              <div>
                <h3 style="color: black">
                  Route Lido Dar Chabane , 8000 Nabeul
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  Tel +216 24 289 289
                </h3>
              </div>
              <div>
                <h3 style="color: black">
                  MF : 000MB1704494/H
                </h3>
              </div>
            </div>
            <div class="contact">
              <h3 style="color: white">
                www.bioherbs.tn
              </h3>
            </div>
            <!-- Header: Right Content -->
          </footer>

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold" />
            <span />
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="save"
          >
            confirmer
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { NumberToLetter } from 'convertir-nombre-lettre'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Client',
          formatter: customer => `${customer.company_name}`,
        },
        {
          key: 'customer.address',
          label: 'address',
        },
        {
          key: 'customer.phone',
          label: 'téléphone',
        },
        {
          key: 'customer.tax_identification_number',
          label: 'MF',
        },
      ],
      p: [],
      test: '0',
      order: [],
      lettre: '',
      sommeRemise: 0,
      last: [],
    }
  },
  computed: {
    invoicenum() {
      return this.invoices.length
    },
    generate() {
      return this.last + 1
    },
  },

  created() {
    this.id = this.$route.params.id
    this.getOrder()
    this.getLastInvoices()
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getLastInvoices() {
      await axios.get('/invoices/last/').then(response => {
        this.last = response.data
      })
    },
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async getOrder() {
      await axios.get(`/deliveries/detail/${this.$route.params.id}/`).then(response => {
        this.order = response.data
      })
      if (this.order != null) {
        axios.post(
          '/deliveries-products/details/',
          { delivery: this.order.id, products: this.order.products },
        ).then(res => {
          const result = res.data
          for (let i = 0; i < result.length; i += 1) {
            const qte = result[i].quantity
            const { price } = result[i]
            const { total } = result[i]
            const { id } = result[i]
            this.addnewitem(id, price, qte, result[i].product.name, total, this.order.remise)
          }
        })

        const sommeString = (Number(this.order.total_ttc) + 1).toFixed(3).toString()
        if (sommeString.includes('.') === false) {
          this.lettre = `${NumberToLetter(sommeString)} DINARS `
        } else {
          const [dinar, milim] = sommeString.split('.')
          this.lettre = `${NumberToLetter(dinar)} DINARS , ${NumberToLetter(milim)} Millimes`
        }
      }
    },
    async save() {
      let customer
      if (this.order.customer) {
        customer = this.order.customer.id
      } else {
        customer = null
      }
      const obj = {
        reference: this.generate,
        date: this.order.date,
        status: this.order.status,
        note: this.order.note,
        customer,
        remise: this.order.remise,
        products: this.p,
        total_ht: this.order.total_ht,
        total_ttc: (Number(this.order.total_ttc) + 1).toFixed(3),
        tax: this.order.tax,
      }
      await axios.post('/invoices/convert/', obj).then(response => {
        this.showToast('success', 'top-center', 'Facture ajouté avec succés')
        this.$router.push({
          name: 'facture-show',
          params: { id: response.data },
        })
      })
        .catch(error => {
          this.showToast('danger', 'top-center', error.toString())
        })
    },

    addnewitem(id, price, qte, nom, total, remise) {
      this.p.push({
        id,
        désignation: nom,
        'prix unitaire': price,
        quantité: qte,
        remise: `${remise} %`,
        'montant HT': (total - Number((total * remise) / 100)).toFixed(3),
      })
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
